<template>
  <div class="take-photo-wrapper">
    <div
      class="background"
      :style="{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        background: `url('${require('@/assets/bg/bg-appbar.svg')}') bottom left no-repeat, linear-gradient(to left, #d20051 0%, #ffa300 100%) `,
      }"
    ></div>
    <div class="content">
      <div class="content-header">
        <div class="img-box">
          <img src="@/assets/logos/philip-morris-logo.png" class="logo" alt="philip-morris-logo" />
        </div>
        <VueText sizeLevel="6">Fotoğraf çekimi öncesinde dikkat edilmesi gerekenler</VueText>
      </div>
      <ul class="info-list">
        <li class="info-list-item">
          Her rafın fotoğrafı çekilmeden önce üzerinde bulunan QR kod okutulmalıdır.
        </li>
        <li class="info-list-item">
          Fotoğraf çekimi öncesi dolapların kapağı açılmalıdır.
        </li>
        <li class="info-list-item">
          Fotoğraflar maksimum 1-1.5 metre mesafeden çekilmelidir.
        </li>
        <li class="info-list-item">Raf uygun mesafeden dik açı ile çekilmelidir.</li>
        <li class="info-list-item">Çekilen fotoğrafta tüm etiketler okunabilir olmalıdır.</li>
        <li class="info-list-item">
          Ürünleri ve/veya etiketleri engelleyen tüm objeler kaldırılmalıdır.
        </li>
        <li class="info-list-item">Etiketler doğru ürünün tam altında bulunmalıdır.</li>
        <li class="info-list-item">
          Herhangi bir sorun ile karşılaşırsanız menü üzerinden "Sık sorulan sorular" kısmını
          inceleyebilirsiniz
        </li>
        <VueText
          style="margin-top: 50px; margin-bottom: 20px; cursor: pointer;"
          sizeLevel="4"
          weightLevel="2"
          color="blue-30"
          @click="openTutorialVideo()"
          >Kullanım videosunu izlemek için tıklayınız
          <VueIcon
            @click="openTutorialVideo()"
            :width="constants.icons.chevronDown.width"
            :height="constants.icons.chevronDown.height"
            :iconName="constants.icons.chevronDown.name"
            style="margin-left: 10px;"
          />
        </VueText>

        <BrandVimeoPlayer
          v-if="isTutorialVideoVisible"
          class="video-wrapper"
          ref="vimeoPlayer"
          :data="tutorialVideo"
          :id="tutorialVideo.embedVideo"
        />
        <VueText
          style="margin-top: 30px; cursor: pointer;"
          sizeLevel="4"
          weightLevel="2"
          color="blue-30"
          @click="downloadGuide"
          >Detaylı kullanım kılavuzunu indirmek için tıklayınız.</VueText
        >
      </ul>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import StorageProps from '@/mixins/storageProps.js';
import StaticContent from '@/services/Api/staticContents';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';

export default {
  name: 'ImageRecognition',
  components: { VueText, BrandVimeoPlayer, VueIcon },
  mixins: [StorageProps],
  data() {
    return {
      isTutorialVideoVisible: false,
      tutorialVideo: {
        embedVideo: 'https://vimeo.com/759792291',
        title: 'tutorialVideo',
      },
    };
  },
  computed: {
    constants() {
      return {
        icons: {
          chevronDown: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.chevronDown, color: '#0081f1' },
        },
      };
    },
  },
  methods: {
    downloadGuide() {
      StaticContent.getStaticContent('IRKullanimKilavuzu').then(res => {
        let {
          Data: { content },
        } = res.data;

        const baseUrl = process.env.VUE_APP_CDN_BASE_URL;
        window.open(`${baseUrl}${content}`, '_blank');
      });
    },
    openTutorialVideo() {
      this.isTutorialVideoVisible = !this.isTutorialVideoVisible;
    },
  },
  // created() {
  //   this.setAppBarTitle(`${this.currentUser.name}`);
  // },
};
</script>

<style lang="scss" scoped>
.content {
  max-height: calc(100vh - 180px);
  overflow: auto;
  padding: 15px;
  padding-bottom: calc(55px + env(safe-area-inset-bottom));
  position: relative;
  background-color: #fff;
  width: calc(100% - 40px);
  margin: 0 auto;
}
.content-header {
  display: flex;
  align-items: center;
}
.background {
  height: 110px;
  width: 100%;
  position: absolute;
  top: 0;
  background-size: cover;
}
.layout-default {
  /deep/ .layout-main-holder {
    top: -1px;
  }
}
.img-box {
  width: 60px;
  height: 60px;
  border-radius: 2px;
  border: solid 0.5px #b4c2d3;
  background-color: #ffffff;
  padding: 19px 4px 21px 5px;
  flex-shrink: 0;
  margin-right: 15px;
  .logo {
    max-width: 100%;
  }
}
.info-list {
  list-style: none;
  margin-top: 20px;
  &-item {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: normal;
    color: #79838e;
    padding-left: 18px;
    margin-bottom: 18px;
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin: 7px 10px 29px 0;
      background-image: linear-gradient(315deg, #d20051, #ffa300);
      position: absolute;
      left: 15px;
    }
    a {
      color: #79838e;
    }
  }
  &-subitem {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: normal;
    color: #79838e;
    padding-left: 18px;
    margin-bottom: 18px;

    &:first-child {
      margin-top: 10px;
    }
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin: 7px 10px 29px 0;
      background-image: linear-gradient(315deg, #d20051, #ffa300);
      position: absolute;
      left: 30px;
    }
  }
}
.take-photo {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  &-icon {
    background-image: linear-gradient(315deg, #d20051, #ffa300);
    width: 50px;
    height: 50px;
    border-radius: 50px;
    padding: 17px 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
}
</style>
